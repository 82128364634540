import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTable } from 'react-table';
import BootstrapTable from 'react-bootstrap-table-next';

import { Squares } from 'react-activity';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function PlacesList() {
  let history = useHistory();

  const location = useLocation();

  const [places, setPlaces] = useState([]);

  function redirectAddPlace() {
    history.push('/add/:new');
  }

  function view(id) {
    history.push({
      pathname: '/editPlace',
      state: { place: id, edit: true },
    });
  }

  function report(id) {
    history.push({
      pathname: '/report',
      state: { place: id, },
    });  }

  function del(id) {
    if (window.confirm('Eliminare il locale?')) {
      var localToken = localStorage.getItem('albdif_token');

      axios
        .post('https://api.corinaldoalbergodiffuso.it/deletePlace.php', {
          token: localToken,
          place: id,
        })
        .then(function (response) {
          var response = response.data;
          if (response.status == 'ok') {
            MySwal.fire({
              title: <p>Locale eliminato</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 2500,
              allowOutsideClick: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2600);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('albdif_token');

      axios
        .post('https://api.corinaldoalbergodiffuso.it/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }

    var token = localStorage.getItem('albdif_token');

    axios
      .post('https://api.corinaldoalbergodiffuso.it/retrievePlacesList.php', {
        params: { token: token },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;

        if (response.status == 'ok') {
          var places = JSON.parse(response.message);

          setTimeout(() => {
            setPlaces(places);
          }, 1500);
        }

        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const columns = [
    /*  {
      dataField: 'id',
      text: 'Product ID',
    },*/
    {
      dataField: 'name',
      text: 'Locale',
    },
    {
      dataField: 'id',
      text: 'Azioni',
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col xs={6}>
                <Button
                  onClick={() => view(rowContent)}
                  variant="outline-primary"
                >
                  Vedi
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  onClick={() => report(rowContent)}
                  variant="outline-primary"
                >
                  Report
                </Button>
              </Col>

            </Row>
          </div>
        );
      },
    },
  ];


  return (
    <div className="text-center">
      <Button
        onClick={() => redirectAddPlace()}
        variant="dark"
        style={{ marginBottom: 20 }}
      >
        Aggiungi locale
      </Button>{' '}
      {places.length > 0 && (
        <BootstrapTable keyField="id" data={places} columns={columns} />
      )}
      {places.length < 1 && <Squares></Squares>}
    </div>
  );


}

export default PlacesList;
