import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Uploady from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import UploadPreview from '@rpldy/upload-preview';

import { Squares } from 'react-activity';

import { useHistory } from 'react-router-dom';

import ImageUpload from 'image-upload-react';
//important for getting nice style.
import 'image-upload-react/dist/index.css';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import AutoComplete from 'react-google-autocomplete';

import axios from 'axios';

var validator = require('email-validator');

const MySwal = withReactContent(Swal);

function EditPlace() {
  let history = useHistory();

  const [token, setToken] = useState(null);

  const [editMode, setEditMode] = useState(false);

  const [oldPlace, setOldPlace] = useState(0);


  const [clearPassword, setClearPassword] = useState('')


  const [thisPlaceId, setThisPlaceId] = useState(0)

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('albdif_token');
      setToken(localToken);

      axios
        .post('https://api.corinaldoalbergodiffuso.it/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }

    var params = history.location.state;

    try {
      if (params.edit === true) {
        setEditMode(true);

        setOldPlace(history.location.state.place);

        try {
          var localToken = localStorage.getItem('albdif_token');

          axios
            .post(
              'https://api.corinaldoalbergodiffuso.it/retrievePlacesData.php',
              {
                token: localToken,
                place: history.location.state.place,
              }
            )
            .then(function (response) {
              var response = response.data;
              if (response.status == 'ok') {
                var data = JSON.parse(response.message);

                console.log(data);

                //  console.log(data.place_data)

                setCounterValue(data.place_data.counterValue);

                //  setCounterValue(data.place_data.counterValue)

                setEmail(data.place_data.email);

                setLatLng(data.place_data.lat + ',' + data.place_data.lng);

                setLongDescription(data.place_data.longDescription);

                setName(data.place_data.name);

                setPhone(data.place_data.phone);

                setShortDescription(data.place_data.shortDescription);

                setClearPassword(data.place_data.clear_password)

                setThisPlaceId(data.place_data.id)

                //setThisPlaceId(JSON.stringify(data))


                setImageSrc(data.place_data.image);



                var checks = data.checks;


                checks.forEach(function (resp) {
                  if (resp.fk_days_parts_id === 1) {
                    setBreakfastChecked(true);
                  }
                  if (resp.fk_days_parts_id === 2) {
                    setLunchChecked(true);
                  }
                  if (resp.fk_days_parts_id === 3) {
                    setSnackChecked(true);
                  }
                  if (resp.fk_days_parts_id === 4) {
                    setDinnerChecked(true);
                  }
                });

                var packs = data.packs;


                packs.forEach(function (resp) {

                  if (resp.type == 'breakfast') {
                    setBreakfastPack(resp.content);
                  }
                  if (resp.type == 'lunch') {
                    setLunchPack(resp.content);
                  }
                  if (resp.type == 'snack') {
                    setSnackPack(resp.content);
                  }
                  if (resp.type == 'dinner') {
                    setDinnerPack(resp.content);
                  }
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (e) {}
      }
    } catch (e) {}
  }, []);

  const [imageSrc, setImageSrc] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    console.log(imageSrc, 'imagesrc');
  }, [imageSrc]);

  const [breakfastChecked, setBreakfastChecked] = useState(false);
  const [lunchChecked, setLunchChecked] = useState(false);
  const [snackChecked, setSnackChecked] = useState(false);
  const [dinnerChecked, setDinnerChecked] = useState(false);

  const [breakfastPack, setBreakfastPack] = useState('');
  const [lunchPack, setLunchPack] = useState('');
  const [snackPack, setSnackPack] = useState('');
  const [dinnerPack, setDinnerPack] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [counterValue, setCounterValue] = useState(0);

  const [latLng, setLatLng] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');

  function toggleCheckbox(type) {
    if (type == 'breakfast') {
      setBreakfastChecked(!breakfastChecked);
    } else if (type == 'snack') {
      setSnackChecked(!snackChecked);
    } else if (type == 'lunch') {
      setLunchChecked(!lunchChecked);
    } else if (type == 'dinner') {
      setDinnerChecked(!dinnerChecked);
    }
  }

  function finalAdd() {
    // alert(imageSrc);

    if (email.length < 1 || phone.length < 1 || name.length < 1) {
      MySwal.fire({
        title: <p>Compila i campi</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    if (!breakfastChecked && !snackChecked && !dinnerChecked && !lunchChecked) {
      MySwal.fire({
        title: <p>Seleziona un'opzione per il locale</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    if (!validator.validate(email)) {
      MySwal.fire({
        title: <p>Email non corretta</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    if (typeof imageSrc === 'undefined' || imageSrc.length < 10) {
      MySwal.fire({
        title: <p>Immagine non caricata correttamente</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    var yyy = latLng.split(',');
    var lat = yyy[0];
    var lng = yyy[1];

    var blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    fetch(imageSrc)
      .then((res) => res.blob())
      .then(blobToBase64)
      .then((finalResult) => {
        //setImageSrc(finalResult)
        axios
          .post('https://api.corinaldoalbergodiffuso.it/editPlace.php', {
            token: token,
            name: name,
            email: email,
            phone: phone,
            counterValue: counterValue,
            breakfastChecked: breakfastChecked,
            lunchChecked: lunchChecked,
            snackChecked: snackChecked,
            dinnerChecked: dinnerChecked,
            breakfastPack: breakfastPack,
            lunchPack: lunchPack,
            snackPack: snackPack,
            dinnerPack: dinnerPack,
            image: finalResult,
            description: shortDescription,
            longDescription: longDescription,
            lat: lat,
            lng: lng,
           // editMode: editMode,
            place: thisPlaceId,
            password: clearPassword
          })
          .then(function (response) {
            var response = response.data;

            console.log(response)


            if (response.includes('ok')) {
      
                MySwal.fire({
                  title: <p>Locale modificato correttamente</p>,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 2500,
                  allowOutsideClick:false

                });
             
              setTimeout(() => {
                history.push('/placesList');
              }, 3000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(!pageLoaded);
    }, 1000);
  }, []);

  const filterBySize = (file) => {
    //filter out images larger than 5MB
    return file.size <= 5242880;
  };

  if (!pageLoaded) {
    return (
      <div className="text-center">
        <Squares></Squares>
      </div>
    );
  }

  return (
    <div>
      <Form>
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Nome attività {thisPlaceId}
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Nome attività"
              onChange={(v) => setName(v.target.value)}
              value={name}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            E-mail
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={(v) => setEmail(v.target.value)}
              value={email}
            />
          </Col>
        </Form.Group>

     {editMode && <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Password"
              onChange={(v) => setClearPassword(v.target.value)}
              value={clearPassword}
            />
          </Col>
        </Form.Group> }   



        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Cellulare principale
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Cellulare principale"
              onChange={(v) => setPhone(v.target.value)}
              value={phone}
            />
          </Col>
        </Form.Group>

        

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Opzioni del locale
          </Form.Label>
          <Col sm={2} style={{ marginTop: 5 }}>
            <Form.Check
              label="Colazione"
              checked={breakfastChecked}
              onChange={() => toggleCheckbox('breakfast')}
            />
            {breakfastChecked && (
              <Form.Control
                as="textarea"
                rows={3}
                style={{ marginTop: 10 }}
                placeholder={'Incluso nel pacchetto'}
                onChange={(v) => setBreakfastPack(v.target.value)}
                value={breakfastPack}
              />
            )}
          </Col>
          <Col sm={2} style={{ marginTop: 5 }}>
            <Form.Check
              label="Pranzo"
              checked={lunchChecked}
              onChange={() => toggleCheckbox('lunch')}
            />
            {lunchChecked && (
              <Form.Control
                as="textarea"
                rows={3}
                style={{ marginTop: 10 }}
                placeholder={'Incluso nel pacchetto'}
                onChange={(v) => setLunchPack(v.target.value)}
                value={lunchPack}
              />
            )}
          </Col>
          <Col sm={2} style={{ marginTop: 5 }}>
            <Form.Check
              label="Merenda"
              checked={snackChecked}
              onChange={() => toggleCheckbox('snack')}
            />
            {snackChecked && (
              <Form.Control
                as="textarea"
                rows={3}
                style={{ marginTop: 10 }}
                placeholder={'Incluso nel pacchetto'}
                onChange={(v) => setSnackPack(v.target.value)}
                value={snackPack}
              />
            )}
          </Col>
          <Col sm={2} style={{ marginTop: 5 }}>
            <Form.Check
              label="Cena"
              checked={dinnerChecked}
              onChange={() => toggleCheckbox('dinner')}
            />
            {dinnerChecked && (
              <Form.Control
                as="textarea"
                rows={3}
                style={{ marginTop: 10 }}
                placeholder={'Incluso nel pacchetto'}
                onChange={(v) => setDinnerPack(v.target.value)}
                value={dinnerPack}
              />
            )}
          </Col>
        </Form.Group>

        <hr />
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Immagine del locale
          </Form.Label>
          <Col sm={10}>
            <ImageUpload
              handleImageSelect={handleImageSelect}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              style={{
                width: 100,
                height: 100,
                background: 'gray',
              }}
            />
          </Col>
        </Form.Group>

        <hr />

     {/*   <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Controvalore CAD
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              placeholder="Controvalore CAD in EUR"
              onChange={(v) => setCounterValue(v.target.value)}
              step=".01"
              value={counterValue}
            />
          </Col>
        </Form.Group>  */}
        <hr />

        {/*   <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Indirizzo
          </Form.Label>
          <Col sm={10}>
            <AutoComplete
                      placeholder="Scrivi un indirizzo..."

              apiKey={'AIzaSyCY2Rphpt6-A3vN2HGVD221kvkHo0Ue0mY'}
              onPlaceSelected={(place) => console.log(place)}
              style={{ width: "100%", height:'35px',  borderRadius:'5px', borderColor:'#e6e6e6' }}
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "it" },
              }}
              defaultValue="Italy"
              />
          </Col>
            </Form.Group> */}

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Latitudine, Longitudine
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Lat,lng"
              onChange={(v) => setLatLng(v.target.value)}
              step=".01"
              value={latLng}
            />
          </Col>
        </Form.Group>

        <hr />

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Descrizione Breve
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Descrizione Breve"
              onChange={(v) => setShortDescription(v.target.value)}
              step=".01"
              value={shortDescription}
            />
          </Col>
        </Form.Group>

        <hr />

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Descrizione lunga
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Descrizione Lunga"
              onChange={(v) => setLongDescription(v.target.value)}
              step=".01"
              value={longDescription}
            />
          </Col>
        </Form.Group>

        <hr />

        

        <Form.Group as={Row}>
          <Col sm={{ span: 10, offset: 2 }}>
            <Button onClick={() => finalAdd()} type="button">
              {editMode ? 'Modifica locale' : 'Aggiungi locale'}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default EditPlace;
