import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Squares } from 'react-activity';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { Checkbox } from 'pretty-checkbox-react';


var validator = require('email-validator');

const MySwal = withReactContent(Swal);

function AddCustomer() {
  const [token, setToken] = useState(null);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('+39');
  const [people, setPeople] = useState(1);
  const [smsToSendChecked, setSmsToSendChecked] = useState(true)

  let history = useHistory();

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('albdif_token');
      setToken(localToken);

      axios
        .post('https://api.corinaldoalbergodiffuso.it/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }
  }, []);

  var params = history.location.state;

  const [pageLoaded, setPageLoaded] = useState(false);

  function finalAddCustomer() {

    console.log(name,phone,people)
    if (name.length < 1 || phone.length < 1 || people < 1) {


      MySwal.fire({
        title: <p>Compila correttamente i campi.</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    try {
      var localToken = localStorage.getItem('albdif_token');

      axios
        .post('https://api.corinaldoalbergodiffuso.it/addCustomer.php', {
          token: localToken,
          name: name,
          phone: phone,
          people: people,
          email: email,
          sendSms: smsToSendChecked
        })
        .then(function (response) {
          var response = response.data;
          console.log(response);
          if(response.status=='ok') {

            MySwal.fire({
              title: <p>Cliente aggiunto correttamente</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 2500,
              allowOutsideClick: false
            });

            setTimeout(() => {
              history.push('/customers')
            }, 2510);

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {}
  }

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(!pageLoaded);
    }, 1000);
  }, []);

  if (!pageLoaded) {
    return (
      <div className="text-center">
        <Squares></Squares>
      </div>
    );
  }

  return (
    <div>
      <Form>
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Cognome e nome cliente
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Cognome e nome cliente"
              onChange={(v) => setName(v.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            E-mail
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={(v) => setEmail(v.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Cellulare principale
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Cellulare"
              onChange={(v) => setPhone(v.target.value)}
              value={phone}
            />
          </Col>
        </Form.Group>

        <hr />

       {/*  <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Numero account
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              placeholder="Numero account"
              onChange={(v) => setPeople(v.target.value)}
              step="1"
              min="1"
              value={people}
            />
          </Col>
        </Form.Group>
        <hr />
        */}



<Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Invia SMS al cliente
          </Form.Label>
          <Col sm={10} style={{marginTop:10}}>
          <Checkbox checked={smsToSendChecked} onChange={() => setSmsToSendChecked(!smsToSendChecked)}></Checkbox>
          </Col>
        </Form.Group>


        <Form.Group as={Row}>
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="button" onClick={() => finalAddCustomer()}>
              Aggiungi cliente
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default AddCustomer;
