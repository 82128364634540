import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Button from 'react-bootstrap/Button';

function ViewPlace({ route }) {
  let history = useHistory();

  let place = history.location.state.place

  console.log(history);

  const [places, setPlaces] = useState([]);

  function view(id) {
    alert(id);
  }

  function del(id) {
    alert(id);
  }

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('albdif_token');

      axios
        .post('https://api.corinaldoalbergodiffuso.it/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }

    var token = localStorage.getItem('albdif_token');

    axios
      .post('https://api.corinaldoalbergodiffuso.it/retrievePlacesList.php', {
        params: { token: token },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;

        if (response.status == 'ok') {
          var places = JSON.parse(response.message);

          setPlaces(places);
        }

        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const columns = [
    /*  {
      dataField: 'id',
      text: 'Product ID',
    },*/
    {
      dataField: 'name',
      text: 'Locale',
    },
    {
      dataField: 'id',
      text: 'Azioni',
      formatter: (rowContent, row) => {
        return (
          <div>
            <Button onClick={() => view(rowContent)} variant="outline-primary">
              Vedi
            </Button>{' '}
            <Button onClick={() => del(rowContent)} variant="outline-danger">
              Elimina
            </Button>{' '}
          </div>
        );
      },
    },
  ];

  /* const products = [{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 }]; */

  return <BootstrapTable keyField="id" data={places} columns={columns} />;

  //return <div></div>;
}

export default ViewPlace;
